<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>We offer four different subscription options for {{ $store.state.brandInfo.displayName }}</p>
								<p><br /></p>
								<p><strong>Important! Price increase February 1 2021.</strong></p>
								<div
									style="
										box-sizing: border-box;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-size: 14px;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									New pricing began on <b style="box-sizing: border-box; font-weight: 700">February 1</b> and is listed below:
								</div>
								<div
									style="
										box-sizing: border-box;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-size: 14px;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									1 month = 24.99 USD
								</div>
								<div
									style="
										box-sizing: border-box;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-size: 14px;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									3 months = 51.99 USD
								</div>
								<div
									style="
										box-sizing: border-box;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-size: 14px;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									6 months = 89.99 USD
								</div>
								<div
									style="
										box-sizing: border-box;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-size: 14px;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									12 months = 159.99 USD
								</div>
								<p><strong>NOTE: All prices are in USD</strong></p>
								<p><br /></p>
								<p>We accept:</p>
								<p v-if="!isPizza">-Credit card</p>
								<p>-CryptoCurrency (Bitcoin<span v-if="!isPizza">/Ethereum/Litecoin</span>)</p>
								<p>
									<strong
										><span style="font-size: 16px; background-color: rgb(243, 121, 52)"><br /></span
									></strong>
								</p>
								<p>
									<strong><span style="font-size: 18px; background-color: rgb(243, 121, 52)">Sorry we no longer accept Paypal</span></strong>
								</p>
								<p>
									<span style="font-size: 14px"><br /></span>
								</p>
								<p>
									<span style="font-size: 14px" v-if="!isPizza"><strong>Please select Credit card or Cryptocurrency</strong></span>
								</p>
								<p>
									<span style="font-size: 14px"><br /></span>
								</p>
								<p>
									<span style="font-size: 14px"
										><strong dir="ltr"
											>We offer 10% discount in bitcoin. Note that this discount does not includes Binance and Crypto.com due to high transaction free. not in
											simplex aswell.
										</strong></span
									>
								</p>
								<p>
									<span style="font-size: 14px"><br /></span>
								</p>
								<p>
									<strong><span style="font-size: 14px">All packages have the same amount of connections/ip.</span></strong>
								</p>
								<p>
									<strong><span style="font-size: 14px">Packages can NOT be modified to receive a discount for less connections.</span></strong>
								</p>
								<p><br /></p>
								<p>You can choose your subscription and purchase <a href="/#pricing" rel="noreferrer noopener" target="_blank">here</a></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'whatIsThePrice',

	data() {
		return {
			title: this.$route.meta.title
		};
	},
	computed: {
		...mapState({
			isPizza: (state) => state.brandInfo.name === 'pizzatime'
		})
	}
};
</script>

<style scoped></style>
